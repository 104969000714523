<template>
  <v-container>
    <!-- BOC:[breadcrumbs] -->
    <ABreadcrumb :items="breadcrumbs"></ABreadcrumb>
    <!-- EOC -->
    <AError
      v-if="api.isError"
      :api="api"
      :callbackReset="() => (userApi.arror = false)"
    ></AError>
    <v-card class="mx-auto pa-3" max-width="800" outlined min-height="200">
      <v-row>
        <v-col cols="12" sm="6">
          <h2>Edit Institution</h2>
        </v-col>
        <v-col cols="12" sm="6">
          <div class="d-flex justify-center justify-sm-end">
            <div>
              <v-btn rounded color="red" dark :to="{name:'PageEducatorInstitutionDelete',params:{id:this.$route.params.id}}" large> Delete </v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-card-title></v-card-title>
      <div class="d-flex align-center justify-space-around">
        <div class="form-content text-center">
          <v-form ref="formName" v-model="valid" lazy-validation>
            <div class="text-center pt-3">
              <TextField
                :value="name"
                label="Institution name"
                :rules="nameRules"
                type="text"
                @input="name = $event"
              ></TextField>
            </div>
            <div class="text-center pt-1">
              <v-btn :loading="api.isLoading" width="75%" rounded color="primary" dark @click="submit()">
                Submit
              </v-btn>
            </div>
            <div class="text-center pt-2">
              <v-btn
                width="75%"
                rounded
                outlined
                color="primary"
                dark
                @click="$router.go(-1)"
              >
                Cancel
              </v-btn>
            </div>
          </v-form>
        </div>
      </div>
    </v-card>
  </v-container>
</template>
    
    <script>
import TextField from "@/components/Form/TextField.vue";
import { mapState } from "vuex";
export default {
  components: {
    TextField,
  },
  computed: mapState({
    auth: (state) => state.auth.data,
  }),
  props: [
    //
  ],
  data: () => ({
    //BOC:[breadcrumbs]
    breadcrumbs: [],
    //EOC
    name: null,
    isWalkin: false,
    nameRules: [],
    id:null,
    //BOC:[api]
    institutionApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
  }),
  created() {
   
    this.institutionApi.method = "GET";
    this.institutionApi.url =
      this.$api.servers.sso +
      "/api/v1/" +
      this.$_getLocale() +
      "/educator/institution/" +
      this.$route.params.id;

      this.institutionApi.callbackReset = () => {
      this.institutionApi.isLoading = true;
      this.institutionApi.isError = false;
    };

    this.institutionApi.callbackError = (e) => {
      this.institutionApi.isLoading = false;
      this.institutionApi.isError = true;
      this.institutionApi.error = e;
    };

    this.institutionApi.callbackSuccess = (resp) => {
      this.institutionApi.isLoading = false;
      this.name = resp.name
      this.isWalkin = resp.enableWalkin
      this.updateBreadcrumb(resp);
    };

    this.api.url =
      this.$api.servers.sso +
      "/api/v1/" +
      this.$_getLocale() +
      "/educator/institution/edit";

    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };

    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };

    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.$router.push({ name: "PageEducatorDashboard" });
      console.log(resp);
    };
  },
  mounted() {
    this.$api.fetch(this.institutionApi);
    this.id = this.$route.params.id;
  },
  watch: {
    name: function () {
      this.nameRules = [];
    },
  },
  methods: {
    updateBreadcrumb(resp) {
      this.breadcrumbs.push({
        text: "Dashboard",
        to: { name: "PageEducatorDashboard" },
        exact: true,
      });
      this.breadcrumbs.push({
        text: resp.name,
        to: {
          name: "PageEducatorInstitutionDashboard",
          params: { id: this.$route.params.id },
        },
        exact: true,
      });

      this.breadcrumbs.push({
        text: 'Edit',
        to: {
          name: "PageEducatorInstitutionEdit",
          params: { id: this.$route.params.id },
        },
        exact: true,
      });

    },
    close() {
      this.$router.push({ name: "PageEducatorDashboard" });
    },
    submit() {
      this.nameRules = [(v) => !!v || "Name is required"];
      let self = this;
      setTimeout(function () {
        if (self.$refs.formName.validate()) {
          self.api.params = {
            id:self.id,
            name: self.name,
          };
          self.$api.fetch(self.api);
        } else {
          self.api.callbackError("Please check the form.");
        }
      });
    },
  },
};
</script>
<style scoped>
.menu-item {
  border: 1px solid black;
  margin: 3px;
}
.form-content {
  width: 300px !important;
}
</style>